<template lang="pug">
div
  v-menu(
    min-width="350px"
    left
    bottom
  )
    template( v-slot:activator="{ on, attrs }" )
      v-btn(
        v-bind="attrs"
        v-on="on"
        class="mr-4 hover:bg-blue-900"
        fab
        text
        small
      )
        v-badge(
          :content="notificationViews.total"
          :value="notificationViews.total"
          color="red"
        )
          v-icon( color="white" ) mdi-bell
    v-card(
      flat
    )
      v-card-title.w-full
        .d-flex.align-center.justify-space-between.w-full
          .d-flex.flex-grow-1
            v-icon.mr-4 mdi-bell
            .text-gray-600 Notifications
          v-btn(
            text
            small
            color="grey"
            @click="clearNew"
          ) CLEAR
      v-card-text.px-0.pb-0.overflow-y-auto.max-h-96
        v-simple-table.border-t
          tbody
            tr( v-if="notificationViews.data.length === 0" )
              td.text-center.py-8.text-gray-500.text-sm.px-16 No new notifications
            tr.relative.cursor-pointer(
              :class="{ 'bg-gray-100' : view.status !== 'selected' }"
              v-for="view of notificationViews.data"
              :key="view.id"
            )
              td.py-4.px-8(
                @click="goToExpense(view.notification.to, view.id)"
              )
                h5.pr-8(
                  :class="['text-' + view.notification.statusContext.color.icon + '-700']"
                )
                  h5( :class="['text-' + view.notification.statusContext.color.icon + '-700']" ) {{ view.notification.title }}
                  .mb-2.text-xs.text-gray-400( v-html="subTitleFormatted(view.notification.subTitle)" )
                  .text-sm.text-gray-500 {{ $day(view.createdAt).format('MMM Do') }}
                    |&nbsp;- {{ $day(view.createdAt).format('hh:mm a') }}
                .absolute( style="top: calc(50% - 8px); right: 8px;" )
                  .bg-red-500.rounded-full.w-3.h-3(
                    v-if="['new'].includes(view.notification.status)"
                  ) &nbsp;
      v-card-actions.border-t
        div(
          @click="to('/notifications')"
          class="text-blue-600 mt-2 cursor-pointer hover:text-blue-900 text-center w-full text-sm"
        ) View All

  v-menu(
    left
    bottom
  )
    template( v-slot:activator="{ on, attrs }" )
      v-btn(
        v-bind="attrs"
        v-on="on"
        class="mr-4 hover:bg-blue-900"
        fab
        text
        small
      )
        v-icon( color="white" ) mdi-account-circle
    v-card( flat )
      v-card-text.px-0.pb-0
        .mx-auto.text-center
          v-icon( large ) mdi-account-circle
        .px-6.py-4.pt-4
          .text-gray-900 {{ fullName }}
          .text-gray-500 {{ email }}
          div(
            @click="to('/account')"
            class="text-blue-600 mt-2 cursor-pointer hover:text-blue-900"
          ) Profile &amp; Preferences

        template( v-if="isAdmin" )
          .px-6.pb-2.pt-6.border-t
            .text-gray-900 {{ name }}
            div(
              @click="to('/org-settings')"
              class="text-blue-600 mt-2 cursor-pointer hover:text-blue-900"
            ) Settings

          v-divider.mt-4

        .border-t( class="hover:bg-gray-100 py-2 cursor-pointer" @click="logout" )
          .d-flex.mx-6.align-center.rounded-md
            div( class="text-blue-600 hover:text-blue-900 py-2" ) Logout
            v-spacer
            v-icon.py-2( small ) mdi-logout
</template>

<script>
import { computed, onMounted } from '@vue/composition-api';

export default {
  name: 'AppBarIcons',
  setup(props, { root: { $FeathersVuex, $store, $router } }) {
    const { NotificationView, Expense } = $FeathersVuex.api

    const {
      fullName,
      email,
      roleId,
      organization: { name }
    } = $store.getters['auth/user'];
    const to = (route) => $router.push(route)
    const logout = async () => {
      await $store.dispatch('auth/logout')
      localStorage.removeItem('expenseStatuses')
      $router.go('/login')
    }

    const subTitleFormatted = (subTitle) => subTitle.split('|').join(' &#183; ');

    const goToExpense = async (to, id) => {
      const patchNotification = new NotificationView({ id })
      await patchNotification.patch({ data: { status: 'selected' } })
      $router.push(to)
    }

    const findExpenses = () => {
      const params = {
        query: {
          $limit: 10,
          status: { $in: ['new'] },
          $sort: { createdAt: -1 },
          $joinEager: '[notification]'
        }
      }

      NotificationView.find(params)
    };
    onMounted(() => findExpenses())
    Expense.on('created', findExpenses)
    Expense.on('patched', findExpenses)

    const notificationViews = computed(() => {
      const params = {
        query: {
          $limit: 10,
          status: { $in: ['new'] },
          $sort: { createdAt: -1 }
        }
      }

      const { data, total } = NotificationView.findInStore(params)
      return { data, total }
    })

    const clearNew = () => {
      const patchParams = [null, { status: 'viewed' }, { query: { 'status': 'new' } }]
      $store.dispatch('notification-views/patch', patchParams);
    }

    return {
      fullName,
      email,
      name,
      notificationViews,
      isAdmin: roleId === 5,

      to,
      logout,
      goToExpense,
      clearNew,
      subTitleFormatted
    }
  }
}
</script>
